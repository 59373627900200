import { delay, put, takeLatest } from 'redux-saga/effects'
import { profileActions } from './slice'
import { AxiosError, AxiosResponse } from 'axios'
import {
  getKYCStatusReq,
  getOrganizationsReq,
  startKYCProcessReq,
} from './providers'
import { profileDomains } from './selectors'
import { customToast } from 'app/components/toast/CustomToast'
import { txErrorMessage } from 'utils/helpers'

export function* getKYCStatus() {
  yield put(profileActions.setKYCLoading(true))
  try {
    const response: AxiosResponse = yield getKYCStatusReq()

    yield put(profileActions.setKYC(response?.data?.data))
  } catch (error: any) {
    console.log(error)
    const errorObj =
      error?.error?.response?.data?.error ||
      error?.response?.data?.error ||
      error
    yield put(profileActions.setKYCError(errorObj))
  } finally {
    yield put(profileActions.setKYCLoading(false))
  }
}
export function* startKYCProcess() {
  try {
    yield put(profileActions.setKYCLoading(true))
    const response: AxiosResponse = yield startKYCProcessReq()

    yield put(profileActions.setKYCQrCode(response?.data?.data))
    yield put(profileActions.setDisplayModal(true))
    yield put(profileActions.checkKYC())
  } catch (error: any) {
    yield put(profileActions.setKYCLoading(false))
    console.log('🚀 ~ startKYCProcess ~ error:', error)
    if (error.error.response?.status === 422) {
      const errorMessage = error.error.response.data.error.message
      console.log('🚀 ~ startKYCProcess ~ error:', error)
      if (errorMessage.includes('user fields')) {
        console.log('🚀 ~ startKYCProcess ~ error:', error)
        let suffix: string[] = []
        if (errorMessage.toLowerCase().includes('country')) {
          suffix.push('nationality')
        }
        if (errorMessage.toLowerCase().includes('dob')) {
          suffix.push('date of birth')
        }
        customToast.error(
          `Please verify the documents on Corepass: ${suffix.join(', ')}`,
        )
      }
    }

    // const errorMessage = txErrorMessage(error)
    // customToast.error(errorMessage)
  } finally {
    yield put(profileActions.setKYCLoading(false))
  }
}

export function* checkKYC() {
  yield put(profileActions.setKYCLoading(true))
  const qrCodeModal: boolean = yield profileDomains.displayModal
  while (qrCodeModal) {
    try {
      const response: AxiosResponse = yield getKYCStatusReq()
      console.log('🚀 ~ checkKYC ~ response:', response)
      yield put(profileActions.setKYC(response?.data?.data))
      yield put(profileActions.getOrganizations())
      yield put(profileActions.setDisplayModal(false))
      break
    } catch (error) {
      console.log(error)
      yield delay(5000)
    }
  }
  yield put(profileActions.setKYCLoading(false))
}

export function* getOrganizations() {
  try {
    const organizationsList: AxiosResponse = yield getOrganizationsReq()
    yield put(profileActions.setOrganizations(organizationsList?.data?.data))
    const selectedOrganization = localStorage['Organization-UUID']
    if (!selectedOrganization) {
      // set the first One as the selected organization
      const firstOrganizationUUId =
        organizationsList?.data?.data?.[0]?.organizationUUID
      localStorage.setItem('Organization-UUID', firstOrganizationUUId)
      yield put(profileActions.setSelectedOrganization(firstOrganizationUUId))
    } else {
      localStorage.setItem('Organization-UUID', selectedOrganization)
      yield put(profileActions.setSelectedOrganization(selectedOrganization))
    }
  } catch (error) {
    console.log(error)
    // const errorMessage = txErrorMessage(error)
    // customToast.error(errorMessage)
  }
}

export function* profileSaga() {
  yield takeLatest(profileActions.getKYC.type, getKYCStatus)
  yield takeLatest(profileActions.startKYCProcess.type, startKYCProcess)
  yield takeLatest(profileActions.checkKYC.type, checkKYC)
  yield takeLatest(profileActions.getOrganizations.type, getOrganizations)
}
