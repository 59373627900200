// Video data interface
export interface VideoData {
  id: string
  title: string
  description: string
  hoverText: string
  duration: string
  views?: string
  rating?: string
  channel?: string | null
  image: string
  bannedIn?: string[]
}

// Mock data for adult videos
export const mockVideos: VideoData[] = [
  {
    id: '103076071',
    title: 'Surprise threesome with stepsister and stepbrother!',
    description:
      'A shocking encounter turns into an unexpected threesome between step-siblings.',
    hoverText:
      'Family fantasy gets wild - watch stepsiblings explore forbidden desires',
    duration: '11:54',
    views: '139,384',
    rating: '83%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202308/24/438039001/original/(m=eafT8f)(mh=sk2IKsIZSsxrHtBd)16.jpg',
  },

  {
    id: '190915871',
    title: 'Office Babe HOTEL HOOKUP during professional BUSINESS TRIP',
    description:
      'Executive assistant meets her boss for more than just business in a luxury hotel.',
    hoverText: 'Business trip turns steamy in upscale hotel room',
    duration: '11:41',
    views: '212,631',
    rating: '86%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/07/461534191/original/(m=eafT8f)(mh=KFrFPT2uoj8X1yh7)0.jpg',
    bannedIn: ['Hungary'],
  },
  {
    id: '190783301',
    title:
      'Hot wife Eden Ivy fucks her female masseuse in front of her husband',
    description:
      'Married couple spices things up by inviting a professional masseuse for a special session.',
    hoverText: 'Sensual massage turns into explosive threesome',
    duration: '11:36',
    channel: 'Purgatory X',
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/06/461530901/original/(m=eafT8f)(mh=7965snPum0HZHVnd)0.jpg',
    bannedIn: ['USA'],
  },
  {
    id: '190070851',
    title: 'Fucked a stranger at a sorority pool party',
    description:
      'College party gets wild when two strangers connect by the pool.',
    hoverText: 'Spontaneous hookup during wild college celebration',
    duration: '08:36',
    views: '256,173',
    rating: '84%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202407/31/455874771/original/(m=eafT8f)(mh=qKaVJTufOcSI-_lZ)0.jpg',
  },
  {
    id: '190800501',
    title: 'Forbidden Step Mom Love ~ Free Full Movie',
    description:
      'Comprehensive family drama unfolds in this feature-length production.',
    hoverText: 'Taboo relationship explored in feature-length film',
    duration: '44:04',
    channel: 'Perv Mom',
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/09/461624211/original/(m=eafT8f)(mh=obQstFrQy-umqnmu)0.jpg',
    bannedIn: ['Hungary', 'USA'],
  },
  {
    id: '190985671',
    title: 'Using Little Step Sister Pussy To Jerk Off',
    description:
      'Sibling boundaries are crossed in this family fantasy scenario.',
    hoverText: 'Taboo home encounter between step-siblings',
    duration: '14:38',
    views: '385,983',
    rating: '85%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202409/24/458217521/original/(m=eafT8f)(mh=p7IoPECJgJOmbV7C)0.jpg',
  },
  {
    id: '103755471',
    title: 'Lifeselector - Fuck compilation of your wildest fantasy babes',
    description:
      'Collection of the most desired actresses in various fantasy scenarios.',
    hoverText: 'Ultimate fantasy compilation with top performers',
    duration: '10:54',
    channel: 'LifeSelector',
    image:
      'https://fi1-ph.ypncdn.com/videos/202402/27/448765011/original/(m=eafT8f)(mh=wIn1nA80W3uHW-T2)0.jpg',
  },
  {
    id: '190742431',
    title: 'First day of college - the teacher FUCKS her HARD and ROUGH!',
    description:
      'New student gets a surprising welcome from her professor on campus.',
    hoverText:
      'College freshman gets special attention from experienced professor',
    duration: '14:02',
    views: '551,590',
    rating: '80%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202406/01/453247412/original/(m=eafT8f)(mh=jg8fAW3cfWWK29TF)2.jpg',
  },
  {
    id: '102847591',
    title: 'HOTTEST TEEN MASSEUSES COMPILATION!',
    description:
      'Collection of the best massage sessions that evolve into more intimate encounters.',
    hoverText: 'Multiple massage sessions that go beyond relaxation',
    duration: '40:39',
    channel: 'Nuru Massage',
    image:
      'https://fi1-ph.ypncdn.com/videos/202309/23/439940201/original/(m=eafT8f)(mh=jAcwK3t9aOQ9zomL)0.jpg',
  },
  {
    id: '190895301',
    title: 'Wife invites 2 men to hotel room separately',
    description:
      'Married woman arranges private meetings in an upscale hotel suite.',
    hoverText: "Married woman's secret hotel encounters",
    duration: '18:07',
    views: '570,152',
    rating: '88%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202410/26/459685891/original/(m=eafT8f)(mh=EJpdtVLIOXepBdpz)0.jpg',
    bannedIn: ['USA'],
  },
  {
    id: '190587001',
    title: 'Baby Doll anal fuck vid with cum-in-mouth ending.',
    description:
      'Petite performer enjoys intense backdoor action with a messy finale.',
    hoverText: 'Intense backdoor action with satisfying conclusion',
    duration: '15:26',
    channel: 'First Anal Quest',
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/10/460318411/original/(m=eafT8f)(mh=Sxj6CwoKniPvSWPN)7.jpg',
  },
  {
    id: '190736791',
    title:
      'My Fucked Mouth is filled with Cum and Squirt. Am I a Good Whore? Compilation',
    description: 'Collection of intense oral encounters with messy endings.',
    hoverText: 'Ultimate oral intensity compilation',
    duration: '31:23',
    views: '970,723',
    rating: '82%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/05/460080381/thumbs_35/(m=eafT8f)(mh=I2Gm8facle3Seq8I)10.jpg',
    bannedIn: ['Hungary'],
  },
  {
    id: '189478791',
    title:
      "She's just 5' 2\" tall with an amazing ass in those Daisy Duke shorts",
    description:
      'Petite woman in revealing cutoff shorts attracts lots of attention.',
    hoverText: 'Petite beauty in tiny shorts gets what she wants',
    duration: '09:13',
    channel: 'Exxx Teens',
    image:
      'https://fi1-ph.ypncdn.com/videos/202406/12/453708671/original/(m=eafT8f)(mh=Pc3-6EZCQ7J7u2ir)0.jpg',
    bannedIn: ['USA'],
  },
  {
    id: '190986231',
    title: 'Perverts! Sexy stepsister fucks stepbro without condom',
    description:
      'Family fantasy where protective measures are forgotten in the heat of passion.',
    hoverText: 'Risky encounter between step-siblings',
    duration: '13:37',
    views: '544,274',
    rating: '86%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202502/07/464258215/thumbs_49/(m=eafT8f)(mh=etqCJZpR6NDXp8Bg)13.jpg',
    bannedIn: ['Hungary', 'USA'],
  },
  {
    id: '190654441',
    title:
      'Cute College Girl With Big Natural Tits has sex outdoors FULL SCENE',
    description:
      'Student risks getting caught during an outdoor adventure with a stranger.',
    hoverText: "Busty student's risky public encounter - complete scene",
    duration: '31:36',
    channel: 'Public Agent',
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/13/460468781/original/(m=eafT8f)(mh=3V0_TJ3Px4eAWoDZ)0.jpg',
    bannedIn: ['Hungary'],
  },
  {
    id: '190589661',
    title:
      'BUSTY HORNY MILF gets DRILLED HARD in the bathroom by STRANGER after her GYM session',
    description:
      'Fitness enthusiast has an unexpected encounter after her workout.',
    hoverText: 'Post-workout passion in gym bathroom',
    duration: '15:24',
    views: '519,823',
    rating: '85%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202410/12/459043421/original/(m=eafT8f)(mh=bKql9KpqtXJ5n92G)0.jpg',
    bannedIn: ['USA'],
  },
  {
    id: '190600411',
    title: 'Slutty Hot Curvy Latina Wife Caitlin Bell Gets Extra Dick',
    description:
      "Married woman explores outside her relationship with her husband's knowledge.",
    hoverText: 'Latina wife enjoys extramarital adventure',
    duration: '13:44',
    channel: 'Hot Wife XXX',
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/07/460210491/original/(m=eafT8f)(mh=K021zXnSBCTabWLD)0.jpg',
  },
  {
    id: '190807131',
    title:
      "Babysitter Do You Want To Keep Your Job?! You'll Have To Cheat On Your Husband!",
    description:
      'Childcare provider faces a difficult choice to maintain her employment.',
    hoverText: 'Young nanny faces compromising situation with employer',
    duration: '34:01',
    views: '535,096',
    rating: '84%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202410/17/459252061/original/(m=eafT8f)(mh=OaQdDZod7rfKaVFH)0.jpg',
  },
  {
    id: '190599651',
    title: 'Alice Murkovski - Anal for skinny teen after photoshoot',
    description:
      'Modeling session takes an unexpected turn after the cameras stop clicking.',
    hoverText: 'Photo session leads to backdoor adventure',
    duration: '16:08',
    channel: 'Slim 4K',
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/11/460366231/original/(m=eafT8f)(mh=9GdVxeJ95E56Z0br)0.jpg',
  },
  {
    id: '190542311',
    title:
      'Beautiful British Lily Phillips Squirt And Comes To Maximo Garcia Big Dick',
    description:
      'UK beauty has an intense reaction to her well-endowed partner.',
    hoverText: 'British beauty experiences powerful climax',
    duration: '10:29',
    views: '862,254',
    rating: '85%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202410/09/458908271/original/(m=eafT8f)(mh=r05b8kqp3eQGiwxX)16.jpg',
  },
  {
    id: '189887471',
    title: 'Best Of Hot Brunette Asian Cocksuckers',
    description: 'Compilation featuring skilled performers of Asian descent.',
    hoverText: 'Asian performers showcase their oral talents',
    duration: '31:15',
    channel: 'Glory Hole Secrets',
    image:
      'https://fi1-ph.ypncdn.com/videos/202408/06/456172391/original/(m=eafT8f)(mh=V721neFIOqIT04SP)0.jpg',
  },
  {
    id: '189918961',
    title:
      'Ela me ajuda a gozar rapidamente todas as manhãs quando estamos sozinhos em casa',
    description:
      'Portuguese language video about morning encounters while alone at home.',
    hoverText: 'Morning ritual when the house is empty',
    duration: '08:16',
    views: '833,845',
    rating: '88%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202404/17/451210451/original/(m=eafT8f)(mh=3sS-Dj6KhuuZ4CJ5)0.jpg',
    bannedIn: ['Hungary', 'USA'],
  },
  {
    id: '103055711',
    title: 'Erotic Fuck For Brunette Beauty - Lana Rhoades',
    description: 'Sensual encounter with famous performer in artistic setting.',
    hoverText: 'Passionate scene with renowned adult star',
    duration: '13:02',
    channel: 'X Empire',
    image:
      'https://fi1-ph.ypncdn.com/videos/202310/30/442109301/original/(m=eafT8f)(mh=4N4t58EPLr6TcH5T)0.jpg',
  },
  {
    id: '190931201',
    title: 'Horny Big Tit Roommate got Fucked on cam for the first time',
    description:
      'Apartment sharing leads to unexpected intimacy caught on camera.',
    hoverText: "Busty roommate's first on-camera experience",
    duration: '12:41',
    views: '784,526',
    rating: '86%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202501/05/462780171/original/(m=eafT8f)(mh=M5TbvM-BGFwjviG_)0.jpg',
  },
  {
    id: '190521491',
    title: 'Big Booty Haley Spades Goes Full Cosplay For Big Dick',
    description:
      'Fantasy character roleplay with popular performer in costume.',
    hoverText: 'Gaming fantasy comes to life with costumed performer',
    duration: '13:57',
    channel: 'New Sensations',
    image:
      'https://fi1-ph.ypncdn.com/videos/202410/24/459600131/original/(m=eafT8f)(mh=PFUSv4DhH2MI06QM)0.jpg',
  },
  {
    id: '190852281',
    title:
      'The Prettiest Pussy Pornstar with Perfect Ass Martina Smeraldi Hardcore Fuck',
    description: 'Italian performer showcases her assets in intense scene.',
    hoverText: 'Italian beauty in passionate hardcore scene',
    duration: '10:56',
    views: '639,147',
    rating: '86%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202409/25/458262541/original/(m=eafT8f)(mh=qIXGSAfQSStS2LAR)6.jpg',
  },
  {
    id: '103047041',
    title: 'TUSHYRAW Curvaceous cutie Jessie gapes wide open for you',
    description: 'Intensive backdoor action with ample view of the experience.',
    hoverText: 'Extreme backdoor stretching with curvaceous performer',
    duration: '12:00',
    channel: 'Tushy Raw',
    image:
      'https://fi1-ph.ypncdn.com/videos/202310/27/441955781/original/(m=eafT8f)(mh=Cb5JdwBGD9AXODY2)0.jpg',
  },
  {
    id: '190863821',
    title: "Bonnie's first-person morning sex tape",
    description:
      "Personal amateur footage shot from participant's perspective.",
    hoverText: 'Intimate POV morning encounter',
    duration: '14:33',
    views: '672,752',
    rating: '83%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/07/461547431/original/(m=eafT8f)(mh=Se4Zi8h7b2tGJFcs)10.jpg',
  },
  {
    id: '102899971',
    title: 'Brunette Francis Restrepo Oiled Up & Fucked By Her Big Dick Boss',
    description:
      'Office power dynamics shift when employee and boss get physical.',
    hoverText: 'Slippery workplace encounter with the boss',
    duration: '17:30',
    channel: 'Operacion Limpieza',
    image:
      'https://fi1-ph.ypncdn.com/videos/202310/02/440483611/original/(m=eafT8f)(mh=I6WIXpYn2q3LqPRu)16.jpg',
  },
  {
    id: '190956361',
    title: 'Big Titty Goth Girl Experiments With Step Dad',
    description:
      'Alternative lifestyle enthusiast crosses family boundaries in taboo scenario.',
    hoverText: 'Gothic stepdaughter explores forbidden relationship',
    duration: '13:57',
    views: '391,849',
    rating: '83%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202410/26/459686171/thumbs_31/(m=eafT8f)(mh=oltn1gDmYSGc4mb0)11.jpg',
  },
  {
    id: '190808671',
    title:
      'TUTOR4K. Studying Mature Pussy. Hot sex with private teacher Julia North',
    description:
      'Educational session evolves into hands-on experience with older instructor.',
    hoverText: 'Private lessons with experienced teacher go beyond academics',
    duration: '16:25',
    channel: 'Tutor 4K',
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/10/461675511/original/(m=eafT8f)(mh=K7s5cAorbk30t7Co)0.jpg',
  },
  {
    id: '190887841',
    title:
      "Step son Fucks Step mom's Pussy and Cum in Her Mouth While She Talks to Husband on the Phone",
    description: 'Risky family encounter during important phone conversation.',
    hoverText: "Stepmom's risky phone call with husband during taboo encounter",
    duration: '11:51',
    views: '854,754',
    rating: '84%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/24/462284871/original/(m=eafT8f)(mh=VaB65Zkwei7fpMv1)0.jpg',
  },
  {
    id: '190561301',
    title: 'Hot blonde in three different hot actions',
    description:
      'One performer demonstrates versatility in multiple scenarios.',
    hoverText: 'Blonde beauty showcases her range in three scenarios',
    duration: '15:32',
    channel: 'XES.PL',
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/06/460158521/original/(m=eafT8f)(mh=HsAoaGX84MQNkg-k)13.jpg',
  },
  {
    id: '190821701',
    title: 'My stepbrother gives me an oily massage that ended in a squirt',
    description:
      'Relaxation session between step-siblings takes unexpected turn.',
    hoverText: 'Innocent massage leads to intense climax between step-siblings',
    duration: '22:43',
    views: '559,804',
    rating: '75%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202312/20/445108811/thumbs_5/(m=eafT8f)(mh=IHDPtG8AusFweOii)5.jpg',
  },
  {
    id: '190671841',
    title:
      'Big Booty Latina Rides Big Cock Until She Gets Her Face Sprayed With Cum',
    description:
      'Enthusiastic partner demonstrates her skills and receives facial finale.',
    hoverText: "Latina beauty's enthusiastic riding leads to messy finish",
    duration: '15:20',
    channel: 'Exotic4K',
    image:
      'https://fi1-ph.ypncdn.com/videos/202411/21/460801531/original/(m=eafT8f)(mh=YR2rhZM4IkC-zlyj)0.jpg',
  },
  {
    id: '190494001',
    title: 'Delivery Girl Messed Up An Order, So She Was Fucked by a Client',
    description:
      'Food delivery mistake leads to unexpected compensation arrangement.',
    hoverText:
      'Service industry mistake leads to unusual customer satisfaction',
    duration: '13:15',
    views: '225,348',
    rating: '73%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202409/28/458376291/original/(m=eafT8f)(mh=jFS3ebZNcUw3h1cf)0.jpg',
  },
  {
    id: '190806891',
    title: "Sexy maid makes a mess in boss's pants",
    description:
      'Housekeeping staff provides extra services not in the job description.',
    hoverText: 'Cleaning service includes unexpected personal attention',
    duration: '10:36',
    views: '333,915',
    rating: '79%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202409/14/457768531/original/(m=eafT8f)(mh=Ebmc0HhJfwimdYBe)0.jpg',
  },
  {
    id: '190748921',
    title: 'Ani Black Fox in anal doggystyle fuck buddy scene',
    description:
      'Named performer showcases her talents in a specific position.',
    hoverText: 'Intense backdoor action in favorite position',
    duration: '18:13',
    channel: 'ItsPOV',
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/02/461314491/original/(m=eafT8f)(mh=xR0fHT_K2b3mbzER)0.jpg',
  },
  {
    id: '189933591',
    title:
      "I CONVINCE MY STEPFATHER BY GIVING HIM MY ASS SO HE WON'T LEAVE HOME",
    description: 'Family drama resolved through intimate persuasion.',
    hoverText: 'Desperate measures to preserve family unity',
    duration: '20:03',
    views: '1,037,222',
    rating: '85%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202407/19/455353481/original/(m=eafT8f)(mh=9gY0gscNfO2Nyl78)0.jpg',
  },
  {
    id: '190810891',
    title: 'Curvy Bitch Squirts On MONSTER Cock',
    description:
      'Well-endowed performer causes intense reaction from voluptuous partner.',
    hoverText:
      'Endowed performer triggers explosive reaction from curvy partner',
    duration: '15:29',
    channel: 'All Black X',
    image:
      'https://fi1-ph.ypncdn.com/videos/202412/07/461544101/original/(m=eafT8f)(mh=zCPcz5hy0Xd-oZQJ)12.jpg',
  },
  {
    id: '190954051',
    title: 'Barber Girl while hair cutting extremely Horny for her Client',
    description:
      'Haircut appointment leads to more personal grooming services.',
    hoverText: 'Barber shop service goes beyond standard grooming',
    duration: '11:44',
    views: '867,196',
    rating: '81%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202408/31/457176171/thumbs_42/(m=eafT8f)(mh=gqPSjEVRQEfk8-tJ)15.jpg',
  },
  {
    id: '102980311',
    title: "Heather Dew Got Her Man's Sperm On Her Tongue After Hard Sex!",
    description:
      'Intense encounter concludes with oral finale for named performer.',
    hoverText: 'Intense session ends with oral finale',
    duration: '06:20',
    channel: 'Hot Babes 4K',
    image:
      'https://fi1-ph.ypncdn.com/videos/202310/17/441332331/original/(m=eafT8f)(mh=FBdFjrg64kYCAira)6.jpg',
  },
  {
    id: '190798281',
    title: 'Horny Anal fuck with EX during kinky Rubbing',
    description:
      'Former couple reconnects with massage leading to adventurous activities.',
    hoverText:
      'Massage reunion with ex-partner leads to intense backdoor action',
    duration: '15:27',
    views: '1,121,656',
    rating: '77%',
    channel: null,
    image:
      'https://fi1-ph.ypncdn.com/videos/202408/24/456874841/original/(m=eafT8f)(mh=3BO9m1MsZTm5KbdT)0.jpg',
  },
  // Additional entries to reach 50 videos

  {
    id: '190001002',
    title: 'Sensual Massage Turns Into Multiple Orgasms',
    description:
      'Professional massage therapist uses special techniques to induce pleasure.',
    hoverText: 'Expert massage leads to uncontrollable climax',
    duration: '19:27',
    channel: 'Massage Fantasy',
    image: 'https://example.com/random-image-2.jpg',
  },
  {
    id: '190001003',
    title: 'College Roommates Caught By Surprise Visitor',
    description:
      'Dormitory encounter interrupted by unexpected third party who joins in.',
    hoverText: 'Dorm room privacy invaded by welcome intruder',
    duration: '22:15',
    views: '785,123',
    rating: '84%',
    channel: null,
    image: 'https://example.com/random-image-3.jpg',
  },
  {
    id: '190001004',
    title: "Submissive Secretary Fulfills Boss's Every Command",
    description:
      'Office power dynamics explored through dominant-submissive relationship.',
    hoverText: 'Office hierarchy taken to extreme levels',
    duration: '26:34',
    channel: 'Power Dynamics',
    image: 'https://example.com/random-image-4.jpg',
  },

  {
    id: '190001006',
    title: 'Outdoor Adventure Turns Wild In The Forest',
    description:
      'Hiking trip becomes much more exciting when couple finds secluded clearing.',
    hoverText: 'Nature lovers find ultimate connection in secluded woods',
    duration: '15:49',
    channel: 'Nature Encounters',
    image: 'https://example.com/random-image-6.jpg',
  },
]
