/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import {
  PurchasedPackages,
  ContainerState,
  PackagesState,
  RequiredOrOptionalFieldsInputObjectType,
  NewSourceKey,
  PaymentProviderItem,
} from './types'
import { createSlice } from 'store/toolkit'
import { KYC_PROPERTIES } from './constants'

const selectedFieldsObject: RequiredOrOptionalFieldsInputObjectType = {}

KYC_PROPERTIES.forEach(property => {
  selectedFieldsObject[property.label] = {
    checked: false,
    selectedChildItem: '',
    openAccordion: false,
  }
})

export const initialNewSourceKey: ContainerState['newSourceKey'] = {
  clientDomainUuid: '',
  requiredFields: selectedFieldsObject,
  optionalFields: selectedFieldsObject,
  orderId: '',
  paymentLink: '',
  quantity: undefined,
  sourceKeyUUID: '',
  renewal: false,
  plan: '',
  renewalSelectedFields: {
    renewalFields: [],
    renewalOptionalFields: [],
  },
}
// The initial state of the Applications container
export const initialState: ContainerState = {
  purchasedPackages: [],
  newSourceKey: initialNewSourceKey,
  displayModal: false,
  isLoadingPackages: false,
  isLoadingFieldPackages: false,
  paymentProviders: [],
  detailsModalSelectedItem: '',
  isLoadingGettingPaymentLink: false,
  isFetchingPackagePrice: false,
  isBuyingVideo: false,
}

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    getPurchasedPackages() {},
    getEncrytions() {},
    setPurchasedApplications(
      state,
      action: PayloadAction<PurchasedPackages[]>,
    ) {
      state.purchasedPackages = action.payload
    },
    setNewPackageValues(state, action: PayloadAction<Partial<NewSourceKey>>) {
      state.newSourceKey = { ...state.newSourceKey, ...action.payload }
    },
    setSelectedPackagePrice(
      state,
      action: PayloadAction<PackagesState['selectedPackagePrice']>,
    ) {
      state.selectedPackagePrice = action.payload
    },
    handleFieldToggle(
      state,
      action: PayloadAction<{ label: string; required: boolean }>,
    ) {
      const label = action.payload.label
      const required = action.payload.required

      if (required) {
        state.newSourceKey.requiredFields[label].checked
          ? (state.newSourceKey.requiredFields = {
              ...state.newSourceKey.requiredFields,
              [label]: {
                ...state.newSourceKey.requiredFields[label],
                checked: false,
                openAccordion: false,
                selectedChildItem: '',
              },
            })
          : (state.newSourceKey.requiredFields = {
              ...state.newSourceKey.requiredFields,
              [label]: {
                ...state.newSourceKey.requiredFields[label],
                checked: true,
              },
            })
      } else {
        state.newSourceKey.optionalFields[label].checked
          ? (state.newSourceKey.optionalFields = {
              ...state.newSourceKey.optionalFields,
              [label]: {
                ...state.newSourceKey.optionalFields[label],
                checked: false,
                openAccordion: false,
                selectedChildItem: '',
              },
            })
          : (state.newSourceKey.optionalFields = {
              ...state.newSourceKey.optionalFields,
              [label]: {
                ...state.newSourceKey.optionalFields[label],
                checked: true,
              },
            })
      }
    },
    handleAccordionToggle(
      state,
      action: PayloadAction<{ label: string; required: boolean }>,
    ) {
      const label = action.payload.label
      const required = action.payload.required
      if (required) {
        state.newSourceKey.requiredFields[label].openAccordion =
          !state.newSourceKey.requiredFields[label].openAccordion
      } else {
        state.newSourceKey.optionalFields[label].openAccordion =
          !state.newSourceKey.optionalFields[label].openAccordion
      }
    },
    handleRadioToggle(
      state,
      action: PayloadAction<{
        label: string
        required: boolean
        value: string
      }>,
    ) {
      const label = action.payload.label
      const value = action.payload.value
      const required = action.payload.required
      if (required) {
        state.newSourceKey.requiredFields[label].selectedChildItem = value
        state.newSourceKey.requiredFields[label].checked = true
      } else {
        state.newSourceKey.optionalFields[label].selectedChildItem = value
        state.newSourceKey.optionalFields[label].checked = true
      }
    },
    createNewSourceKeyFromForm() {},
    createNewSourceKey(
      state,
      action: PayloadAction<{
        clientDomainUuid: string
        quantity: number
        sourceKeyUUID: string
        renewal: boolean
        plan: string
        userDefinedID: string
      }>,
    ) {},
    buyVideo(
      state,
      action: PayloadAction<{
        clientDomainUuid: string
        fields: string[]
        optionalFields: string[]
        plan: string
        providerCode: string
        quantity: number
        userDefinedID: string
      }>,
    ) {},
    handleCheckboxSelect(state, action: PayloadAction<any>) {
      const label = action.payload.label
      const value = action.payload.value
      const required = action.payload.required
      if (required) {
        //if the value is already in the array, remove it
        if (
          state.newSourceKey.requiredFields[label].selectedChildItem.includes(
            value,
          )
        ) {
          state.newSourceKey.requiredFields[label].selectedChildItem = [
            ...(
              state.newSourceKey.requiredFields[label]
                .selectedChildItem as string[]
            ).filter((item: string) => item !== value),
          ]
        } else {
          state.newSourceKey.requiredFields[label].selectedChildItem = [
            ...(state.newSourceKey.requiredFields[label]
              .selectedChildItem as string[]),
            value,
          ]
        }
      } else {
        //if the value is already in the array, remove it
        if (
          state.newSourceKey.optionalFields[label].selectedChildItem.includes(
            value,
          )
        ) {
          state.newSourceKey.optionalFields[label].selectedChildItem = [
            ...(
              state.newSourceKey.optionalFields[label]
                .selectedChildItem as string[]
            ).filter((item: string) => item !== value),
          ]
        } else {
          state.newSourceKey.optionalFields[label].selectedChildItem = [
            ...(state.newSourceKey.optionalFields[label]
              .selectedChildItem as unknown as string[]),
            value,
          ]
        }
      }
    },
    setDisplayModal(state, action: PayloadAction<boolean>) {
      state.displayModal = action.payload
    },
    setIsLoadingPackages(state, action: PayloadAction<boolean>) {
      state.isLoadingPackages = action.payload
    },
    setIsLoadingFieldPackages(state, action: PayloadAction<boolean>) {
      state.isLoadingFieldPackages = action.payload
    },
    getPaymentProviders() {},
    setPaymentProviders(state, action: PayloadAction<PaymentProviderItem[]>) {
      state.paymentProviders = action.payload
    },
    getPaymentStatus(state, action: PayloadAction<{ orderId: string }>) {},
    calculatePrice(
      state,
      action: PayloadAction<{
        fields: string[]
        optionalfields: string[]
        quantity: number | undefined
      }>,
    ) {},
    setDetailModalSelectedItem(
      state,
      action: PayloadAction<PackagesState['detailsModalSelectedItem']>,
    ) {
      state.detailsModalSelectedItem = action.payload
    },
    setPackageType(state, action: PayloadAction<string>) {
      state.newSourceKey.plan = action.payload
    },
    startPackageRenewal(state, action: PayloadAction<string>) {
      state.displayModal = true
      state.newSourceKey.renewal = true
    },
    setIsLoadingGettingPaymentLink(state, action: PayloadAction<boolean>) {
      state.isLoadingGettingPaymentLink = action.payload
    },
    setIsFetchingPackagePrice(state, action: PayloadAction<boolean>) {
      state.isFetchingPackagePrice = action.payload
    },
    setIsBuyingVideo(state, action: PayloadAction<boolean>) {
      state.isBuyingVideo = action.payload
    },
  },
})

export const {
  actions: PackagesActions,
  reducer: PackagesReducer,
  name: sliceKey,
} = packagesSlice
