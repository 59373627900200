import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { PackagesActions } from '../../../slice'
import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { PACKAGES_TYPES } from 'app/containers/Package/constants'
import { CRadioGroup } from 'app/components/radio-group'

interface Props {}
export const ConfirmPayment: FC<Props> = props => {
  const dispatch = useDispatch()
  const correspondingPackage = useSelector(
    packagesSelectors.selectedPackagePrice,
  )
  const selectedFields = useSelector(
    packagesSelectors.newSourceKeySelectedFields,
  )
  const optionalFields = useSelector(
    packagesSelectors.newSourceKeyOptionalFields,
  )

  const isFetchingPackagePrice = useSelector(
    packagesSelectors.isFetchingPackagePrice,
  )
  const { quantity, plan, renewalSelectedFields, renewal } = useSelector(
    packagesSelectors.newSourceKey,
  )

  useEffect(() => {
    if (plan === PACKAGES_TYPES.BUNDLE) {
      const calculatePriceInput = {
        fields: renewal ? renewalSelectedFields.renewalFields : selectedFields,
        optionalfields: renewal
          ? renewalSelectedFields.renewalOptionalFields
          : optionalFields,
        quantity,
      }
      dispatch(PackagesActions.calculatePrice(calculatePriceInput))
    }

    dispatch(PackagesActions.createNewSourceKeyFromForm())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const radioLabel = () => {
    if (plan === PACKAGES_TYPES.PAY_AS_YOU_GO) {
      return `${quantity}$ worth of KYCs`
    }
    return isFetchingPackagePrice
      ? 'Loading...'
      : `${quantity} KYCs for ${correspondingPackage?.Price}$`
  }

  return (
    <DeatilsContainer>
      <RowContainer>
        <Label>Selected Package:</Label>
        <CRadioGroup
          name="loginOnly"
          value={quantity}
          options={[
            {
              label: radioLabel(),
              value: quantity || '',
            },
          ]}
          handleChange={() => {}}
        />
      </RowContainer>
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`

const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-of-type {
    width: 150px;
    font-weight: 700;
  }
`

const Label = styled('div')``
