import { customToast } from 'app/components/toast/CustomToast'
import { AxiosResponse } from 'axios'
import { put, all, select, takeLatest } from 'redux-saga/effects'
import { txErrorMessage } from 'utils/helpers'
import {
  checkDomainStatusReq,
  createNewApplicationRequest,
  getApplicationsList,
} from './providers'
import { ApplicationsActions } from './slice'
import { applicationsSelectors } from './selectors'
import { ApplicationsState } from './types'
import { profileSelectors } from '../Profile/selectors'
import { kycStatus } from '../Profile/types'
export function* getApplications() {
  yield put(ApplicationsActions.setIsLoadingApplications(true))
  try {
    const response: AxiosResponse = yield getApplicationsList()
    const kyc: kycStatus | undefined = yield select(profileSelectors.kyc)
    const myWalletAddress = kyc?.corepassID
    yield put(
      ApplicationsActions.setApplications(
        response?.data?.data?.clientDomains ?? [],
      ),
    )
    const setedApplications: ApplicationsState['applications'] = yield select(
      applicationsSelectors.applications,
    )
    const doesDomainsContain_pornpass = setedApplications.some(
      application =>
        application.domain === `https://${myWalletAddress}.pornpass.com`,
    )
    if (!doesDomainsContain_pornpass) {
      yield put(
        ApplicationsActions.createNewApplication({
          name: 'pornpass',
          domain: `https://${myWalletAddress}.pornpass.com`,
          bundleId: 'com.pornpass.app',
          framework: 'ReactJS',
          platform: 'WEB',
        }),
      )
    } else {
      const notCheckedApplications = setedApplications.filter(
        application => application.status === 'UNVERIFIED',
      )
      if (notCheckedApplications.length > 0) {
        const arrayToCall: any = notCheckedApplications.map(application =>
          put(ApplicationsActions.checkDomainStatus(application.uuid)),
        )
        yield all(arrayToCall)
      }
    }
  } catch (error) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  } finally {
    yield put(ApplicationsActions.setIsLoadingApplications(false))
  }
}

export function* createNewApplication(
  action: ReturnType<typeof ApplicationsActions.createNewApplication>,
) {
  try {
    const response: AxiosResponse = yield createNewApplicationRequest(action)
    yield put(ApplicationsActions.getApplications())
    yield put(ApplicationsActions.setDisplayModal(false))
    yield put(
      ApplicationsActions.setNewAppValues({
        name: '',
        domain: '',
        bundleId: '',
        framework: '',
      }),
    )
    // customToast.success(response?.data?.message || 'Process succeded')
  } catch (error) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* checkDomainStatus(
  action: ReturnType<typeof ApplicationsActions.checkDomainStatus>,
) {
  try {
    const domainUuid = action.payload
    const response: AxiosResponse = yield checkDomainStatusReq(domainUuid)
    // customToast.success(response?.data?.message || 'Process succeded')
    yield put(ApplicationsActions.getApplications())
  } catch (error: any) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* applicationSaga() {
  yield takeLatest(ApplicationsActions.getApplications.type, getApplications)
  yield takeLatest(
    ApplicationsActions.createNewApplication.type,
    createNewApplication,
  )
  yield takeLatest(
    ApplicationsActions.checkDomainStatus.type,
    checkDomainStatus,
  )
}
