import { useCountdown } from 'hooks/useCountDown'
import { TimeDisplay } from './timeDisplay'
import styled from 'styled-components'

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
    </div>
  )
}

//@ts-ignore
const ShowCounter = ({ minutes, seconds }) => {
  return (
    <ShowCounterStyled>
      <TimeDisplay value={minutes} type={'Mins'} isDanger={false} />
      <p>:</p>
      <TimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
    </ShowCounterStyled>
  )
}
//@ts-ignore
const CountdownTimer = ({ targetDate }) => {
  const [minutes, seconds] = useCountdown(targetDate)

  if (minutes + seconds <= 0) {
    return <ExpiredNotice />
  } else {
    return <ShowCounter minutes={minutes} seconds={seconds} />
  }
}

export default CountdownTimer

const ShowCounterStyled = styled('div')`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  gap: 10px;
`
