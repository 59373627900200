import { PackagesActions } from '../../../slice'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  COLUMN_ALIGN_START_JUSTIFY_START,
  ROW_CENTER,
} from 'styles/globalStyles'
import { packagesSelectors } from 'app/containers/Package/selectors'
import BaseInput from 'app/components/input/baseInput'
import { ContainerState } from 'app/containers/Package/types'
import { PACKAGES_TYPES } from 'app/containers/Package/constants'
interface Props {}
export const QuantitySelection: FC<Props> = props => {
  const dispatch = useDispatch()
  const { quantity, plan } = useSelector(packagesSelectors.newSourceKey)

  useEffect(() => {
    // get the provider code
    dispatch(PackagesActions.getPaymentProviders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      PackagesActions.setNewPackageValues({
        [event.target.name as keyof ContainerState['newSourceKey']]:
          event.target.valueAsNumber,
      }),
    )
  }

  return (
    <DeatilsContainer>
      <RowContainer>
        <Label>
          {plan === PACKAGES_TYPES.BUNDLE
            ? 'Please Enter quantity of your package:'
            : 'Please Enter your desired charge amount($):'}
        </Label>
        <BaseInput
          name="quantity"
          value={quantity}
          type="number"
          onChange={handleChange}
          fullWidth
        />
      </RowContainer>
    </DeatilsContainer>
  )
}

const DeatilsContainer = styled('div')`
  ${COLUMN_ALIGN_START_JUSTIFY_START}
  width: 100%;
`

const RowContainer = styled('div')`
  ${ROW_CENTER}
  gap: 20px;
  & > *:first-of-type {
    width: 250px;
    font-weight: 700;
  }
`

const Label = styled('div')``
