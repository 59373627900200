import { Box, CircularProgress, styled } from '@mui/material'
import { Modal } from 'app/components/modal'
import { useDispatch, useSelector } from 'react-redux'
import { loginActions } from 'app/containers/Login/slice'
import { LoginSelectors } from 'app/containers/Login/selectors'
import PrimaryButton from 'app/components/button/primaryButton'
import { COLUMN_CENTER } from 'styles/globalStyles'
import { H3_BOLD, TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import { LoginStatus } from 'app/containers/Login/types'
import BaseButton from 'app/components/button/baseButton'

export const LoginModal = () => {
  const dispatch = useDispatch()
  const loginState: LoginStatus = useSelector(
    LoginSelectors.loginStatus,
  ) as LoginStatus
  const isLoginModalOpen = useSelector(
    LoginSelectors.isLoginModalOpen,
  ) as boolean

  const handleClose = () => {
    dispatch(loginActions.setIsLoginModalOpen(false))
  }

  const handleLogin = () => {
    dispatch(loginActions.login())
    localStorage.setItem('isLoggingIn', 'true')
  }

  return (
    <CModal open={isLoginModalOpen} onClose={handleClose}>
      <ModalContainer>
        <Title>CorePass Login Required</Title>
        <Body>You need to login with CorePass to continue</Body>
        <ButtonContainer>
          <LoginButton
            onClick={handleLogin}
            disabled={loginState === LoginStatus.loading}
          >
            {loginState === LoginStatus.loading ? (
              <CircularProgress
                color="inherit"
                size={24}
                sx={{ color: 'white' }}
              ></CircularProgress>
            ) : (
              'Login with CorePass'
            )}
          </LoginButton>
          <ContinueButton onClick={handleClose}>
            Continue Browsing
          </ContinueButton>
        </ButtonContainer>
      </ModalContainer>
    </CModal>
  )
}

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 500px !important;
  }

  .MuiDialog-paper {
    max-width: 500px !important;
  }
`

const ModalContainer = styled(Box)`
  ${COLUMN_CENTER};
  gap: 24px;
  padding: 16px;
`

const Title = styled('p')`
  ${H3_BOLD}
  margin: 0;
  color: var(--greyscale-900);
`

const Body = styled('p')`
  ${TEXT_LG_SEMIBOLD}
  margin: 0;
  color: var(--greyscale-700);
  text-align: center;
`

const ButtonContainer = styled(Box)`
  ${COLUMN_CENTER};
  gap: 16px;
  width: 100%;
  margin-top: 16px;
`

const LoginButton = styled(PrimaryButton)`
  width: 100%;
  height: 48px;
`

const ContinueButton = styled(BaseButton)`
  width: 100%;
  height: 48px;
  background-color: var(--greyscale-200) !important;
  color: var(--greyscale-900) !important;

  &:hover {
    background-color: var(--greyscale-300) !important;
  }
`

export default LoginModal
