import { profileSaga } from 'app/containers/Profile/saga'
import { runSaga } from './configureStore'
import { applicationSaga } from 'app/containers/Applications/saga'
import { packagesSaga } from 'app/containers/Package/saga'
import { apiKeysSaga } from 'app/containers/ApiKey/saga'
import { oauthSaga } from 'app/containers/OAuth/saga'
import { roleSaga } from 'app/containers/Roles/saga'
import { webhookSaga } from 'app/containers/Webhook/saga'
import { loginSaga } from 'app/containers/Login/saga'
import { productsSaga } from 'app/containers/Products/saga'
import { globalSaga } from 'app/containers/Global/saga'

export const runSagas = () => {
  runSaga(profileSaga)
  runSaga(productsSaga)
  runSaga(globalSaga)
  runSaga(applicationSaga)
  runSaga(packagesSaga)
  runSaga(apiKeysSaga)
  runSaga(oauthSaga)
  runSaga(roleSaga)
  runSaga(webhookSaga)
  runSaga(loginSaga)
}
