import styled from 'styled-components'
import {
  COLUMN_CENTER,
  COLUMN_JUSTIFY_START_ALIGN_CENTER,
} from 'styles/globalStyles'

export const Wrapper = styled('div')`
  width: 100%;
  ${COLUMN_CENTER}
  height:100vh;
`
export const CardWrapper = styled('div')`
  ${COLUMN_JUSTIFY_START_ALIGN_CENTER}
  width: 432px;
  height: 400px;
  left: 664px;
  padding: 0 40px;
  top: 774px;
  background: var(--white);
  border: 1px solid var(--link-water);
  border-radius: 24px;
`
export const CorePassLogo = styled('img')`
  margin-top: 72px;
`
export const CorePassAdminLogo = styled('img')`
  margin-top: 24px;
`
export const CardDescription = styled('div')`
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--lynch);
`
export const LoadingCardDescription = styled('div')`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--lynch);
  margin-bottom: 10px;
`
export const LoadingCardTitle = styled('div')`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--black-knight);
  margin-top: 24px;
  margin-bottom: 10px;
`
