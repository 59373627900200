import { customToast } from 'app/components/toast/CustomToast'
import { AxiosResponse } from 'axios'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import { txErrorMessage } from 'utils/helpers'
import { createApiKeyReq, getApiKeysList } from './providers'
import { apiKeysDomains } from './selectors'
import { ApiKeysActions } from './slice'

export function* getApiKeys() {
  yield put(ApiKeysActions.setIsLoadingApiKeys(true))
  try {
    const response: AxiosResponse = yield getApiKeysList()
    yield put(ApiKeysActions.setApiKeys(response?.data?.data?.apiKeys ?? []))
  } catch (error) {
    console.log({ error })
  } finally {
    yield put(ApiKeysActions.setIsLoadingApiKeys(false))
  }
}
export function* createApiKey() {
  const clientDomainUUID: string = yield select(apiKeysDomains.ClientDomainUuid)
  try {
    const response: AxiosResponse = yield createApiKeyReq({
      clientDomainUUID,
    })
    const toCall = [
      put(ApiKeysActions.setDisplayModal(false)),
      put(ApiKeysActions.getApiKeys()),
    ]
    yield all(toCall)
    customToast.success(response?.data?.message || 'Process succeded')
  } catch (error) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* apiKeysSaga() {
  yield takeLatest(ApiKeysActions.getApiKeys.type, getApiKeys)
  yield takeLatest(ApiKeysActions.createApiKey.type, createApiKey)
}
