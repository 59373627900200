import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { JWTencodedData } from './types'
import jwt_decode from 'jwt-decode'
const LoginDomains = {
  loginStatus: (state: RootState) =>
    state.login?.loginStatus || initialState.loginStatus,

  tokenLimitEnd: (state: RootState) =>
    state.login?.tokenLimitEnd || initialState?.tokenLimitEnd,
  authCode: (state: RootState) =>
    state.login?.authCode || initialState?.authCode,
  qrcode: (state: RootState) => state.login?.qrcode,
  linkToPhone: (state: RootState) => state.login?.linkToPhone,
  coreId: (state: RootState) => state.login?.coreId,
  isLoggedIn: (state: RootState) => state.login?.loggedIn,
  unauthorized: (state: RootState) => state.login?.unauthorized,
  isLoginModalOpen: (state: RootState) => state.login?.isLoginModalOpen,
}
export const LoginSelectors = {
  loginStatus: createSelector(
    [LoginDomains.loginStatus],
    loginState => loginState,
  ),

  tokenLimitEnd: createSelector(
    [LoginDomains.tokenLimitEnd],
    loginState => loginState,
  ),
  authCode: createSelector([LoginDomains.authCode], loginState => loginState),

  qrcode: createSelector([LoginDomains.qrcode], loginState => loginState),
  linkToPhone: createSelector(
    [LoginDomains.linkToPhone],
    loginState => loginState,
  ),
  coreId: createSelector([LoginDomains.coreId], loginState => {
    const authToken = localStorage.getItem('AUTH_ACCESS_TOKEN')
    if (!authToken) {
      return 'error'
    } else {
      var decodedJWT: JWTencodedData = jwt_decode(authToken)
      const coreID = decodedJWT.sub.split(':')[1]
      return coreID
    }
  }),
  isLoggedIn: createSelector(
    [LoginDomains.isLoggedIn],
    loginState => loginState,
  ),
  unauthorized: createSelector(
    [LoginDomains.unauthorized],
    unauthorized => unauthorized,
  ),
  isLoginModalOpen: createSelector(
    [LoginDomains.isLoginModalOpen],
    isLoginModalOpen => isLoginModalOpen,
  ),
}
