import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import {
  Collapse,
  FormControlLabel,
  FormGroup,
  ListItemIcon,
  Radio,
  RadioGroup,
} from '@mui/material'
import { KYC_PROPERTIES } from 'app/containers/Package/constants'
import { useDispatch, useSelector } from 'react-redux'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { PackagesActions } from 'app/containers/Package/slice'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'

interface Props {
  required: boolean
}
export default function CheckboxListSecondary({ required }: Props) {
  const dispatch = useDispatch()

  const handleClick = (label: string) => {
    if (!required && requiredFields[label].checked) return
    dispatch(PackagesActions.handleAccordionToggle({ label, required }))
  }

  const handleToggle = (label: string) => () => {
    dispatch(PackagesActions.handleFieldToggle({ label, required }))
  }
  const handleRadioToggle = (label: string, value: string) => {
    dispatch(PackagesActions.handleRadioToggle({ label, value, required }))
  }

  const handleCheckboxSelect = (label: string, event: any) => {
    dispatch(
      PackagesActions.handleCheckboxSelect({
        label,
        value: event.target.value,
        required,
      }),
    )
  }

  const fields = useSelector(packagesSelectors.fields({ required }))
  const requiredFields = useSelector(
    packagesSelectors.fields({ required: true }),
  )

  return (
    <StyledList dense>
      {KYC_PROPERTIES?.map?.(({ value, label, propertyRef }, index) => {
        return (
          <ItemContainer key={index}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleClick(label)}>
                <ListItemIcon>
                  <Checkbox
                    onClick={e => e.stopPropagation()}
                    onChange={handleToggle(label)}
                    checked={fields[label].checked}
                    inputProps={{
                      'aria-labelledby': `checkbox-list-secondary-label-${value}`,
                    }}
                    disabled={
                      label === 'Others' ||
                      (!required && requiredFields[label].checked)
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  id={`checkbox-list-secondary-label-${value}`}
                  primary={`${label}`}
                  style={{
                    color:
                      !required && requiredFields[label].checked
                        ? 'gray'
                        : 'black',
                  }}
                />
                {propertyRef.length > 0 &&
                  (fields[label].openAccordion ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  ))}
              </ListItemButton>
            </ListItem>
            {propertyRef.length > 0 && (
              <StyledCollapse
                in={fields[label].openAccordion}
                timeout="auto"
                unmountOnExit
                onClick={e => e.stopPropagation()}
              >
                <List
                  component="div"
                  disablePadding
                  onClick={e => e.stopPropagation()}
                >
                  {label === 'Others' ? (
                    <FormGroup
                      onChange={event => {
                        event.stopPropagation()
                        handleCheckboxSelect(label, event)
                      }}
                    >
                      {propertyRef.map((item, index) => {
                        return (
                          <FormControlLabel
                            key={item.label}
                            value={item.value}
                            control={
                              <Checkbox
                                onClick={e => {
                                  e.stopPropagation()
                                  console.log(fields[label])
                                }}
                                checked={fields[
                                  label
                                ]?.selectedChildItem?.includes?.(item.value)}
                                inputProps={{
                                  'aria-labelledby': `checkbox-list-secondary-label-${value}`,
                                }}
                              />
                            }
                            label={item.label}
                            onClick={e => e.stopPropagation()}
                            componentsProps={{
                              typography: {
                                fontSize: '12px',
                              },
                            }}
                          />
                        )
                      })}
                    </FormGroup>
                  ) : (
                    <RadioGroup
                      value={fields[label].selectedChildItem}
                      onChange={event => {
                        event.stopPropagation()
                        handleRadioToggle(label, event.target.value)
                      }}
                      onClick={e => e.stopPropagation()}
                    >
                      {propertyRef?.map?.((item, index) => {
                        return (
                          <FormControlLabel
                            key={item.label}
                            value={item.value}
                            control={
                              <Radio
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 20,
                                  },
                                }}
                              />
                            }
                            label={item.label}
                            onClick={e => e.stopPropagation()}
                            componentsProps={{
                              typography: {
                                fontSize: '12px',
                              },
                            }}
                          />
                        )
                      })}
                    </RadioGroup>
                  )}
                </List>
              </StyledCollapse>
            )}
          </ItemContainer>
        )
      })}
    </StyledList>
  )
}

const StyledCollapse = styled(Collapse)`
  padding-left: 50px;
  &.MuiFormControlLabel-root {
    font-size: 10px;
  }
`

const StyledList = styled(List)`
  width: 100%;
  background-color: 'background.paper';
  display: flex;
  flex-wrap: wrap;
`

const ItemContainer = styled('div')`
  width: 300px;
`
