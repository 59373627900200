export const baseURL = process.env.REACT_APP_API_BASE_URL

export enum AppPages {
  profile = '/profile',
  Applications = '/applications',
  ApiKeys = '/apikeys',
  Packages = '/packages',
  OAuth = '/oauth',
  Roles = '/roles',
  Webhook = '/webhook',
  Checkout = '/checkout',
  Login = '/login',
  LoginCallBack = '/login/callback',
  RootPage = '/',
  Products = '/products',
  Sidebar = '/',
  NotFoundPage = '*',
}

export enum ROLES_VALUES {
  OWNER = 'OWNER',
}

export const THIRTY_MINUTES = 30 * 60 * 1000
