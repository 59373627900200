/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction } from '@reduxjs/toolkit'
import { LoginStatus, ContainerState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { loginSaga } from './saga'

// The initial state of the Login container
export const initialState: ContainerState = {
  coreId: '',
  authCode: '',
  loginStatus: LoginStatus.init,
  loggedIn: false,
  unauthorized: false,
  isLoginModalOpen: false,
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthCode(state, action: PayloadAction<string>) {
      state.authCode = action.payload
    },
    setAuthCodeWithRefreshToken(state) {},
    setQrCode(state, action: PayloadAction<string>) {
      state.qrcode = action.payload
    },
    setLinkToPhone(state, action: PayloadAction<string>) {
      state.linkToPhone = action.payload
    },

    claimWithAddress() {},
    login() {},
    setCoreId(state, action: PayloadAction<string>) {
      state.coreId = action.payload
    },

    setTokenLimitEnd(state, action: PayloadAction<number>) {
      state.tokenLimitEnd = action.payload
    },

    setLoginStatus(state, action: PayloadAction<LoginStatus>) {
      state.loginStatus = action.payload
    },
    logout(state) {
      state.loggedIn = false
      state.loginStatus = LoginStatus.init
      localStorage.clear()
    },
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = action.payload
      if (action.payload === false) {
        state.unauthorized = false
        localStorage.clear()
      }
    },
    setUnauthorized(state, action: PayloadAction<boolean>) {
      state.unauthorized = action.payload
    },
    setIsLoginModalOpen(state, action: PayloadAction<boolean>) {
      state.isLoginModalOpen = action.payload
    },
  },
})

export const {
  actions: loginActions,
  reducer: loginReducer,
  name: sliceKey,
} = loginSlice

export const useloginSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: loginReducer })
  useInjectSaga({ key: sliceKey, saga: loginSaga })
  return { loginActions }
}
