import { FC } from 'react'
import styled from 'styled-components'
import {
  COLUMN_JUSTIFY_START_ALIGN_CENTER,
  ROW_JUSTIFY_START_ALIGN_CENTER,
} from 'styles/globalStyles'
import {
  B1_Medium_Medium,
  C2_Medium,
  C2_Regular,
} from 'styles/globalTypography'
import { VideoData } from '../mock'
import { useDispatch, useSelector } from 'react-redux'
import { LoginSelectors } from 'app/containers/Login/selectors'
import { loginActions } from 'app/containers/Login/slice'
import { ProductsActions } from '../slice'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { profileSelectors } from 'app/containers/Profile/selectors'
interface VideoCardProps {
  video: VideoData
}

const VideoCard: FC<VideoCardProps> = ({ video }) => {
  const loggedIn = useSelector(LoginSelectors.isLoggedIn)
  const isPurchased = useSelector(
    packagesSelectors.isPackageWithIdPurchased(video?.id || 'not provided'),
  )
  const isOver18 = useSelector(profileSelectors.isOver18)
  const isVideoBannedForMyCountry = useSelector(
    profileSelectors.isVideoBannedForMyCountry(video),
  )
  const bannedIn = video.bannedIn ?? []
  const dispatch = useDispatch()
  const handleClick = () => {
    if (loggedIn) {
      dispatch(ProductsActions.setSelectedVideo(video))
    } else {
      dispatch(loginActions.setIsLoginModalOpen(true))
    }
  }

  return (
    <CardContainer onClick={handleClick}>
      <ThumbnailContainer>
        <VideoThumbnail
          src={video.image}
          alt={video.title}
          style={{
            filter:
              isOver18 && !isVideoBannedForMyCountry ? 'none' : 'blur(10px)',
          }}
        />
        <VideoHoverInfo>{video.hoverText}</VideoHoverInfo>
        <VideoDuration>{video.duration}</VideoDuration>
      </ThumbnailContainer>
      <VideoInfo>
        <VideoTitle>{video.title}</VideoTitle>
        {bannedIn.length > 0 && (
          <div style={{ width: '100%', textAlign: 'left', color: 'orange' }}>
            <span>Banned in:</span>
            <span>{bannedIn.join(', ')}</span>
          </div>
        )}
        <MetadataRow>
          {video.views && <VideoViews>{video.views} views</VideoViews>}
          {video.rating && <VideoRating>{video.rating}</VideoRating>}
        </MetadataRow>
        {video.channel && <VideoChannel>{video.channel}</VideoChannel>}
        {loggedIn && (
          <BuyButtonContainer>
            {isPurchased ? (
              <BuyButton isPurchased>watch full video</BuyButton>
            ) : (
              <BuyButton>buy full video</BuyButton>
            )}
          </BuyButtonContainer>
        )}
      </VideoInfo>
    </CardContainer>
  )
}
const BuyButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
`

const BuyButton = styled.button<{ isPurchased?: boolean }>`
  background-color: ${props => (props.isPurchased ? '#2ecc71' : '#e74c3c')};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;

  &:hover {
    background-color: #c0392b;
  }
`

const CardContainer = styled('div')`
  ${COLUMN_JUSTIFY_START_ALIGN_CENTER}
  width: 300px;
  background: var(--white);
  border-radius: 8px;
  overflow: hidden;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  cursor: pointer;
  margin: 12px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    ${props => `
      & > div:first-of-type > div:nth-of-type(1) {
        opacity: 1;
      }
    `}
  }
`

const ThumbnailContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 168px;
  overflow: hidden;
`

const VideoThumbnail = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${CardContainer}:hover & {
    transform: scale(1.05);
  }
`

const VideoHoverInfo = styled('div')`
  ${C2_Medium}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
`

const VideoDuration = styled('div')`
  ${C2_Medium}
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  z-index: 2;
`

const VideoInfo = styled('div')`
  ${COLUMN_JUSTIFY_START_ALIGN_CENTER}
  width: 100%;
  padding: 12px;
  gap: 8px;
`

const VideoTitle = styled('h3')`
  ${B1_Medium_Medium}
  color: var(--black-knight);
  margin: 0;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  width: 100%;
`

const MetadataRow = styled('div')`
  ${ROW_JUSTIFY_START_ALIGN_CENTER}
  width: 100%;
  gap: 8px;
`

const VideoViews = styled('span')`
  ${C2_Regular}
  color: var(--lynch);
`

const VideoRating = styled('span')`
  ${C2_Regular}
  color: var(--denim);
`

const VideoChannel = styled('div')`
  ${C2_Regular}
  color: var(--lynch);
  width: 100%;
  text-align: left;
`

export default VideoCard
