import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions } from '../../slice'
import { profileSelectors } from '../../selectors'
import {
  COLUMN_ALIGN_END_JUSTIFY_CENTER,
  ROW_JUSTIFY_END_ALIGN_CENTER,
} from 'styles/globalStyles'
import styled from 'styled-components'

import { TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import CountdownTimer from '../countdown'
import { Modal } from 'app/components/modal'

export default function ProfileQrCodeModal() {
  const dispatch = useDispatch()
  const displayModal = useSelector(profileSelectors.displayModal)
  const handleModalClose = () => dispatch(profileActions.setDisplayModal(false))
  const { qrcode, link, expiration } = useSelector(profileSelectors.kycQrCode)

  return (
    <Modal open={displayModal} onClose={handleModalClose}>
      <ModalContentContainer>
        <ModalTitle>
          Scan this code with CorePass and continue your KYC process:
        </ModalTitle>
        <QrCodeImage src={`data:image/png;base64,${qrcode}`} />
        <CorePassLink href={link}>Open CorePass</CorePassLink>
        <RowContainer>
          <CountdownTimer targetDate={expiration} />
        </RowContainer>
      </ModalContentContainer>
    </Modal>
  )
}

const ModalContentContainer = styled(Box)`
  ${COLUMN_ALIGN_END_JUSTIFY_CENTER}
  align-items: center;
`

const ModalTitle = styled('div')`
  ${TEXT_LG_SEMIBOLD}
`

const QrCodeImage = styled('img')`
  width: 400px;
  height: 400px;
`

const RowContainer = styled('div')`
  ${ROW_JUSTIFY_END_ALIGN_CENTER}
  gap:10px;
  width: 100%;
`

const CorePassLink = styled('a')`
  text-decoration: none;
  padding: 10px 20px;
  background-color: var(--mirage);
  border-radius: 10px;
  color: white;
`
