import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { VideoData } from '../Products/mock'

export const profileDomains = {
  root: (state: RootState) => state.profile || initialState,
  kycLoading: (state: RootState) => state.profile.kycLoading,
  kyc: (state: RootState) => state.profile.kyc,
  error: (state: RootState) => state.profile.error,
  kycQrCode: (state: RootState) => state.profile.kycQrCode,
  displayModal: (state: RootState) => state.profile.displayModal,
  organizations: (state: RootState) => state.profile.organizations,
  selectedOrganization: (state: RootState) =>
    state.profile.selectedOrganization,
  dateOfBirth: (state: RootState) => state.profile.kyc?.dateOfBirth,
}

export const profileSelectors = {
  root: createSelector(profileDomains.root, root => root),
  kycLoading: createSelector(
    profileDomains.kycLoading,
    kycLoading => kycLoading,
  ),
  kyc: createSelector(profileDomains.kyc, kyc => kyc),
  error: createSelector(profileDomains.error, error => error),
  clientUuid: createSelector(profileDomains.kyc, kyc => kyc?.uuid),
  kycQrCode: createSelector(profileDomains.kycQrCode, kycQrCode => kycQrCode),
  displayModal: createSelector(
    profileDomains.displayModal,
    displayModal => displayModal,
  ),
  isUserUnregistered: createSelector(
    profileDomains.error,
    error => error?.code === 404 || error?.status === 404,
  ),
  organizations: createSelector(
    profileDomains.organizations,
    organizations => organizations,
  ),
  selectedOrganization: createSelector(
    profileDomains.selectedOrganization,
    selectedOrganization => selectedOrganization,
  ),
  dateOfBirth: createSelector(
    profileDomains.dateOfBirth,
    dateOfBirth => dateOfBirth,
  ),
  isOver18: createSelector(profileDomains.dateOfBirth, dateOfBirth => {
    if (!dateOfBirth) return 0
    const today = new Date()
    const birthDate = new Date(dateOfBirth)
    const ageDiff = today.getFullYear() - birthDate.getFullYear()
    return ageDiff >= 18
  }),
  age: createSelector(profileDomains.dateOfBirth, dateOfBirth => {
    if (!dateOfBirth) return 0
    const today = new Date()
    const birthDate = new Date(dateOfBirth)
    const ageDiff = today.getFullYear() - birthDate.getFullYear()
    return ageDiff
  }),
  country: createSelector(profileDomains.kyc, kyc => kyc?.country),
  isVideoBannedForMyCountry: (video: VideoData | null) =>
    createSelector(profileDomains.kyc, kyc => {
      if (!video) return false
      if (!kyc) return false
      return (
        (video.bannedIn ?? [])
          .map(country => country.toLowerCase())
          .includes(kyc.country.toLowerCase()) ?? false
      )
    }),
}
