import { customToast } from 'app/components/toast/CustomToast'
import { AxiosResponse } from 'axios'
import { put, takeLatest } from 'redux-saga/effects'
import { txErrorMessage } from 'utils/helpers'
import {
  addClientRoleReq,
  getAllClientsReq,
  getRolesReq,
  removeClientReq,
} from './provider'
import { RolesActions } from './slice'

export function* getRoles() {
  try {
    const response: AxiosResponse = yield getRolesReq()

    yield put(
      RolesActions.setRoles(
        response?.data?.data?.map((role: any) => role.role) ?? [],
      ),
    )
  } catch (error: any) {
    console.log(error)
    const errorObj =
      error?.error?.response?.data?.error ||
      error?.response?.data?.error ||
      error
    yield put(RolesActions.setRolesError(errorObj))
  }
}

export function* addClientRole(
  action: ReturnType<typeof RolesActions.addClientRole>,
) {
  try {
    const response: AxiosResponse = yield addClientRoleReq(action)

    yield put(RolesActions.getAllClients())

    customToast.success(response?.data?.message || 'Process succeded')
  } catch (error) {
    console.log(error)
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* getAllClients() {
  yield put(RolesActions.setIsLoading(true))
  try {
    const response: AxiosResponse = yield getAllClientsReq()
    yield put(RolesActions.setAllClients(response?.data?.data ?? []))
  } catch (error: any) {
    console.log(error)
    const errorObj =
      error?.error?.response?.data?.error ||
      error?.response?.data?.error ||
      error
    yield put(RolesActions.setRolesError(errorObj))
  } finally {
    yield put(RolesActions.setIsLoading(false))
  }
}

export function* updateClient(
  action: ReturnType<typeof RolesActions.updateClient>,
) {
  try {
    yield removeClientReq(action.payload.corepassID)
    yield put(RolesActions.addClientRole(action.payload))
  } catch (error) {
    console.log(error)
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* roleSaga() {
  yield takeLatest(RolesActions.getRoles.type, getRoles)
  yield takeLatest(RolesActions.addClientRole.type, addClientRole)
  yield takeLatest(RolesActions.getAllClients.type, getAllClients)
  yield takeLatest(RolesActions.updateClient.type, updateClient)
}
