import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { KYC_PROPERTIES } from './constants'
import { RequiredOrOptionalFieldsInputValueType } from './types'
import { profileDomains } from '../Profile/selectors'
import { applicationsDomains } from '../Applications/selectors'

export const packagesDomains = {
  root: (state: RootState) => state.packages || initialState,
  purchasedPackages: (state: RootState) => state.packages.purchasedPackages,
  newSourceKey: (state: RootState) => state.packages.newSourceKey,
  selectedPackagePrice: (state: RootState) =>
    state.packages.selectedPackagePrice,
  displayModal: (state: RootState) => state.packages.displayModal,
  isLoadingPackages: (state: RootState) => state.packages.isLoadingPackages,
  isLoadingFieldPackages: (state: RootState) =>
    state.packages.isLoadingFieldPackages,
  paymentProviders: (state: RootState) => state.packages.paymentProviders,
  detailsModalSelectedItem: (state: RootState) =>
    state.packages.detailsModalSelectedItem,
  setIsLoadingGettingPaymentLink: (state: RootState) =>
    state.packages.isLoadingGettingPaymentLink,
  isFetchingPackagePrice: (state: RootState) =>
    state.packages.isFetchingPackagePrice,
  isBuyingVideo: (state: RootState) => state.packages.isBuyingVideo,
}

export const packagesSelectors = {
  root: createSelector(packagesDomains.root, root => root),
  purchasedPackages: createSelector(
    [
      packagesDomains.purchasedPackages,
      profileDomains.kyc,
      applicationsDomains.applications,
    ],
    (packages, kyc, applications) => {
      const pornpassApplication = applications.find(
        item => item.domain === `https://${kyc?.corepassID}.pornpass.com`,
      )
      const purchasedPackages = packages.filter(
        item => item.clientDomainUUID === pornpassApplication?.uuid,
      )
      return purchasedPackages
    },
  ),
  newSourceKey: createSelector(
    packagesDomains.newSourceKey,
    newSourceKey => newSourceKey,
  ),
  selectedPackagePrice: createSelector(
    packagesDomains.selectedPackagePrice,
    packages => packages,
  ),
  fields: ({ required }: { required: boolean }) =>
    createSelector(packagesDomains.newSourceKey, newSourceKey =>
      required ? newSourceKey.requiredFields : newSourceKey.optionalFields,
    ),
  displayModal: createSelector(
    packagesDomains.displayModal,
    displayModal => displayModal,
  ),
  isPackageWithIdPurchased: (id: string) =>
    createSelector(
      [
        packagesDomains.purchasedPackages,
        profileDomains.kyc,
        applicationsDomains.applications,
      ],
      (purchasedPackages, kyc, applications) => {
        const pornpassApplication = applications.filter(
          item => item.domain === `https://${kyc?.corepassID}.pornpass.com`,
        )
        const purchasedPackage = purchasedPackages.filter(
          item => item.clientDomainUUID === pornpassApplication[0]?.uuid,
        )
        return purchasedPackage.some(item => item.userDefinedID === id)
      },
    ),
  newSourceKeySelectedFields: createSelector(
    packagesDomains.newSourceKey,
    // map over newSourceKey values and if the selectedChildItem was not empty return that
    // and if it was empty and checked property was true return item key and else return nothing
    newSourceKey => {
      const selectedFields = Object.entries(newSourceKey.requiredFields).map(
        ([key, value]: [string, RequiredOrOptionalFieldsInputValueType]) => {
          if (value.selectedChildItem) {
            return value.selectedChildItem
          } else if (value.checked) {
            return KYC_PROPERTIES.find(property => property.label === key)
              ?.value
          }
          return undefined
        },
      )
      const filteredResult = selectedFields?.filter(Boolean) as string[]
      //if any array is in the selectedFields, flatten it
      if (filteredResult.some((item: any) => Array.isArray(item))) {
        return filteredResult.flat()
      }
      return filteredResult
    },
  ),
  newSourceKeyOptionalFields: createSelector(
    packagesDomains.newSourceKey,
    newSourceKey => {
      const selectedFields = Object.entries(newSourceKey.optionalFields).map(
        ([key, value]: [string, RequiredOrOptionalFieldsInputValueType]) => {
          if (value.selectedChildItem) {
            return value.selectedChildItem
          } else if (value.checked) {
            return KYC_PROPERTIES.find(property => property.label === key)
              ?.value
          }
          return undefined
        },
      )
      const filteredResult = selectedFields?.filter(Boolean) as string[]
      //if any array is in the selectedFields, flatten it
      if (filteredResult.some((item: any) => Array.isArray(item))) {
        return filteredResult.flat()
      }
      return filteredResult
    },
  ),
  isLoadingPackages: createSelector(
    packagesDomains.isLoadingPackages,
    isLoadingPackages => isLoadingPackages,
  ),
  isLoadingFieldPackages: createSelector(
    packagesDomains.isLoadingFieldPackages,
    isLoadingFieldPackages => isLoadingFieldPackages,
  ),
  paymentProviders: createSelector(
    packagesDomains.paymentProviders,
    paymentProviders => paymentProviders,
  ),
  detailsModalSelectedItem: createSelector(
    packagesDomains.detailsModalSelectedItem,
    packagesDomains.purchasedPackages,
    (detailsModalSelectedItem, purchasedPackages) => {
      return purchasedPackages.find(
        purchasedPackage => purchasedPackage.uuid === detailsModalSelectedItem,
      )
    },
  ),
  getPurchasePackageByUUID: (uuid: string) =>
    createSelector(packagesDomains.purchasedPackages, purchasedPackages =>
      purchasedPackages.find(
        purchasedPackage => purchasedPackage.uuid === uuid,
      ),
    ),
  isLoadingGettingPaymentLink: createSelector(
    packagesDomains.setIsLoadingGettingPaymentLink,
    isLoadingGettingPaymentLink => isLoadingGettingPaymentLink,
  ),
  isFetchingPackagePrice: createSelector(
    packagesDomains.isFetchingPackagePrice,
    isFetchingPackagePrice => isFetchingPackagePrice,
  ),
  isBuyingVideo: createSelector(
    packagesDomains.isBuyingVideo,
    isBuyingVideo => isBuyingVideo,
  ),
}
