// Video detail modal

import { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'app/components/modal'
import { productsSelectors } from '../selectors'
import { ProductsActions } from '../slice'
import { VideoData } from '../mock'
import { profileSelectors } from 'app/containers/Profile/selectors'
import { CircularProgress } from '@mui/material'
import { profileActions } from 'app/containers/Profile/slice'
import { packagesSelectors } from 'app/containers/Package/selectors'
import { PackagesActions } from 'app/containers/Package/slice'
import { applicationsSelectors } from 'app/containers/Applications/selectors'

const VideoDetailModal: FC = () => {
  const dispatch = useDispatch()
  const selectedVideo = useSelector(productsSelectors.selectedVideo)
  const isOver18 = useSelector(profileSelectors.isOver18)
  const isLoadingKYC = useSelector(profileSelectors.kycLoading)
  console.log({ isLoadingKYC })
  const isVideoBannedForMyCountry = useSelector(
    profileSelectors.isVideoBannedForMyCountry(selectedVideo),
  )
  const isCheckingAge = useSelector(productsSelectors.isCheckingAge)
  const notRegistered = useSelector(profileSelectors.isUserUnregistered)
  const profileError = useSelector(profileSelectors.error)
  const isBuyingVideo = useSelector(packagesSelectors.isBuyingVideo)
  const pornPassApplication = useSelector(
    applicationsSelectors.pornpassApplication,
  )
  const isPurchased = useSelector(
    packagesSelectors.isPackageWithIdPurchased(
      selectedVideo?.id || 'not provided',
    ),
  )
  const notVerifiedYet = (profileError?.code || 404) == 404
  const handleClose = () => {
    dispatch(ProductsActions.setSelectedVideo(null as unknown as VideoData))
  }

  const handleBuyClick = () => {
    if (selectedVideo) {
      console.log({ pornPassApplication })
      dispatch(
        PackagesActions.buyVideo({
          clientDomainUuid: pornPassApplication?.uuid!,
          fields: ['DOB_PARENT_FIELD'],
          optionalFields: [],
          plan: 'BUNDLE',
          providerCode: 'STRIPE',
          quantity: 1,
          userDefinedID: selectedVideo.id,
        }),
      )
    }
  }
  const isOpen = selectedVideo !== null

  useEffect(() => {
    if (isOpen) {
      dispatch(profileActions.checkKYC())
    }
  }, [isOpen, dispatch])

  const handleVerifyAge = () => {
    dispatch(profileActions.startKYCProcess())
  }
  const handleCheckClick = () => {
    dispatch(profileActions.checkKYC())
  }
  console.log({ notVerifiedYet })
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title={selectedVideo?.title || 'Video Details'}
    >
      {selectedVideo && (
        <VideoDetailsContainer>
          <VideoThumbnail
            style={{
              filter:
                isOver18 && !isVideoBannedForMyCountry ? 'none' : 'blur(10px)',
            }}
            src={selectedVideo.image}
            alt={selectedVideo.title}
          />

          <VideoDescription>{selectedVideo.description}</VideoDescription>

          <VideoMetadata>
            <MetadataItem>
              <MetadataLabel>Duration:</MetadataLabel>
              <MetadataValue>{selectedVideo.duration}</MetadataValue>
            </MetadataItem>

            {selectedVideo.views && (
              <MetadataItem>
                <MetadataLabel>Views:</MetadataLabel>
                <MetadataValue>{selectedVideo.views}</MetadataValue>
              </MetadataItem>
            )}

            {selectedVideo.rating && (
              <MetadataItem>
                <MetadataLabel>Rating:</MetadataLabel>
                <MetadataValue>{selectedVideo.rating}</MetadataValue>
              </MetadataItem>
            )}

            {selectedVideo.channel && (
              <MetadataItem>
                <MetadataLabel>Channel:</MetadataLabel>
                <MetadataValue>{selectedVideo.channel}</MetadataValue>
              </MetadataItem>
            )}
          </VideoMetadata>

          <ActionButtons>
            {notRegistered ? (
              <BuyButton onClick={handleVerifyAge}>
                request for age verification
              </BuyButton>
            ) : isCheckingAge || isBuyingVideo ? (
              <BuyButton disabled>
                <CircularProgress size={16} color="inherit" />
              </BuyButton>
            ) : isPurchased ? (
              <BuyButton
                onClick={() => {
                  window.open(selectedVideo.image, '_blank')
                }}
              >
                watch
              </BuyButton>
            ) : isOver18 && !isVideoBannedForMyCountry ? (
              <BuyButton onClick={handleBuyClick} disabled={isBuyingVideo}>
                Buy Video $9.99
              </BuyButton>
            ) : isOver18 && isVideoBannedForMyCountry ? (
              <BuyButton disabled>
                {parseRestrictionText({
                  isOver18: isOver18,
                  isVideoBannedForMyCountry: isVideoBannedForMyCountry,
                })}
              </BuyButton>
            ) : notVerifiedYet ? (
              <BuyButton onClick={handleCheckClick}>check again</BuyButton>
            ) : (
              <BuyButton disabled>
                {parseRestrictionText({
                  isOver18: isOver18,
                  isVideoBannedForMyCountry: isVideoBannedForMyCountry,
                })}
              </BuyButton>
            )}
          </ActionButtons>
        </VideoDetailsContainer>
      )}
    </Modal>
  )
}

const parseRestrictionText = ({
  isOver18,
  isVideoBannedForMyCountry,
}: {
  isOver18: boolean | number
  isVideoBannedForMyCountry: boolean
}) => {
  if (!isOver18) return 'You must be over 18 to buy this video'
  if (isOver18 && isVideoBannedForMyCountry)
    return 'This video is not available in your country'
  return 'You must be over 18 to buy this video'
}

const VideoDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #222;
  color: #fff;
`

const VideoThumbnail = styled.img`
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
`

const VideoDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  margin: 0;
`

const VideoMetadata = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
`

const MetadataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const MetadataLabel = styled.span`
  font-size: 14px;
  color: #aaa;
  font-weight: 600;
`

const MetadataValue = styled.span`
  font-size: 14px;
  color: #fff;
`

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`

const BuyButton = styled.button`
  flex: 1;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  min-height: 50px;
  &:hover {
    background-color: #c0392b;
  }
`

const AddToCartButton = styled.button`
  flex: 1;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }
`

export default VideoDetailModal
