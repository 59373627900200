/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import {
  ContainerState,
  Organization,
  kycQrCodeResponse,
  kycStatus,
} from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { profileSaga } from './saga'

// The initial state of the Overview container
export const initialState: ContainerState = {
  kycLoading: false,
  error: {
    code: 404,
    message: '',
  },
  kycQrCode: {
    alreadySent: false,
    expiration: 0,
    link: '',
    qrcode: '',
  },
  displayModal: false,
  organizations: [],
  selectedOrganization: '',
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getKYC() {},
    setKYC(state, action: PayloadAction<kycStatus>) {
      state.kyc = action.payload
      state.error = ''
    },
    setKYCLoading(state, action: PayloadAction<boolean>) {
      state.kycLoading = action.payload
    },
    setKYCError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setKYCQrCode(state, action: PayloadAction<kycQrCodeResponse>) {
      state.kycQrCode = action.payload
    },
    startKYCProcess() {},
    setDisplayModal(state, action: PayloadAction<boolean>) {
      state.displayModal = action.payload
    },
    checkKYC() {},
    getOrganizations() {},
    setOrganizations(state, action: PayloadAction<Organization[]>) {
      state.organizations = action.payload
    },
    setSelectedOrganization(state, action: PayloadAction<string>) {
      state.selectedOrganization = action.payload
      localStorage.setItem('Organization-UUID', action.payload)
    },
  },
})

export const {
  actions: profileActions,
  reducer: profileReducer,
  name: sliceKey,
} = profileSlice

export const useProfileSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: profileReducer })
  useInjectSaga({ key: sliceKey, saga: profileSaga })
  return { profileActions: profileActions }
}
