import { FC } from 'react'
import styled from 'styled-components'
import { H4 } from 'styles/globalTypography'
import { VideoData } from '../mock'
import VideoCard from './videoCard'

interface VideoGridProps {
  videos: VideoData[]
  title?: string
}

const VideoGrid: FC<VideoGridProps> = ({ videos, title }) => {
  return (
    <Container>
      {title && <GridTitle>{title}</GridTitle>}
      <Grid>
        {videos.map(video => (
          <VideoCard key={video.id} video={video} />
        ))}
      </Grid>
    </Container>
  )
}

const Container = styled('div')`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px 16px;
`

const GridTitle = styled('h2')`
  ${H4}
  color: var(--black-knight);
  margin: 0 0 24px 0;
  text-align: left;
`

const Grid = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  justify-items: center;
`

export default VideoGrid
