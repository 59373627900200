import { FC, useEffect, useState } from 'react'

import { COLUMN_CENTER } from 'styles/globalStyles'
import { B1_Medium_Medium, TEXT_XL } from 'styles/globalTypography'
import { mockVideos, VideoData } from './mock'
import VideoGrid from './components/videoGrid'
import styled from 'styled-components'
import { loginActions } from '../Login/slice'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LoginSelectors } from '../Login/selectors'
import { LoginStatus } from '../Login/types'
import {
  CardWrapper,
  LoadingCardDescription,
  LoadingCardTitle,
  Wrapper,
} from './styles'
import { CircularProgress } from '@mui/material'
import ProfileQrCodeModal from '../Profile/components/qrCodeModal'
import { VideoDetailModal } from './modals'

import { AgeVerifyBadge } from './components/ageVerifyBadge'
export const Products: FC = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const loginState: LoginStatus = useSelector(LoginSelectors.loginStatus)
  const isLoggedIn = useSelector(LoginSelectors.isLoggedIn)
  useEffect(() => {
    if (!localStorage.getItem('AUTH_ACCESS_TOKEN')) {
      const authCode = searchParams.get('code')
      if (authCode) {
        dispatch(loginActions.setAuthCode(authCode))
      }
    } else {
      dispatch(loginActions.setIsLoggedIn(true))
    }
  }, [dispatch, searchParams])

  // Get the first 8 videos
  const featuredVideos = mockVideos.slice(0, 8)

  // Get videos with rating above 85%
  const highRatedVideos = mockVideos
    .filter(video => video.rating && parseInt(video.rating) > 85)
    .slice(0, 8)

  // Get remaining videos
  const moreVideos = mockVideos
    .filter(
      video =>
        !featuredVideos.includes(video) && !highRatedVideos.includes(video),
    )
    .slice(0, 16)

  if (loginState === LoginStatus.redirectLoading && !isLoggedIn) {
    return (
      <Wrapper>
        <ProfileQrCodeModal />
        <CardWrapper>
          <LoadingCardTitle>Loading</LoadingCardTitle>
          <LoadingCardDescription>
            Waiting for your login to be completed!
          </LoadingCardDescription>
          <CircularProgress></CircularProgress>
        </CardWrapper>
      </Wrapper>
    )
  }

  return (
    <PageContainer>
      <ProfileQrCodeModal />
      <VideoDetailModal />
      <AgeVerifyBadge />
      <VideoGrid title="Featured Videos" videos={featuredVideos} />

      <VideoGrid title="Top Rated" videos={highRatedVideos} />

      <VideoGrid title="More Videos" videos={moreVideos} />
    </PageContainer>
  )
}

const PageContainer = styled('div')`
  width: 100%;
  padding: 24px 0;
  min-height: 100vh;
  background-color: var(--blue-gray);
`

const VideoDetailsContainer = styled('div')`
  ${COLUMN_CENTER}
  width: 100%;
  gap: 24px;
  padding: 16px;
`

const VideoThumbnail = styled('img')`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
`

const VideoDescription = styled('p')`
  ${TEXT_XL}
  color: var(--black-knight);
  margin: 0;
  text-align: left;
  width: 100%;
`

const VideoMetadata = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  width: 100%;
`

const MetadataItem = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const MetadataLabel = styled('span')`
  ${B1_Medium_Medium}
  color: var(--lynch);
  font-weight: 600;
`

const MetadataValue = styled('span')`
  ${B1_Medium_Medium}
  color: var(--black-knight);
`

export default Products
