/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { ContainerState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { globalSaga } from './saga'

// The initial state of the Global container
export const initialState: ContainerState = {}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
})

export const {
  actions: GlobalActions,
  reducer: GlobalReducer,
  name: sliceKey,
} = globalSlice

export const useGlobalSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: GlobalReducer })
  useInjectSaga({ key: sliceKey, saga: globalSaga })
  return { GlobalActions }
}
