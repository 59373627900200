import styled from 'styled-components'

import { COLUMN_JUSTIFY_START_ALIGN_CENTER } from 'styles/globalStyles'

//@ts-ignore
export const TimeDisplay = ({ value, type, isDanger }) => {
  return (
    <TimeDisplayStyled className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{value}</p>
      <span>{type}</span>
    </TimeDisplayStyled>
  )
}

const TimeDisplayStyled = styled('div')`
  ${COLUMN_JUSTIFY_START_ALIGN_CENTER}
  p {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  span {
    font-size: 12px;
    font-weight: 500;
  }
`
