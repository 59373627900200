import { customToast } from 'app/components/toast/CustomToast'
import { AxiosResponse } from 'axios'
import { put, select, takeLatest, call } from 'redux-saga/effects'
import { txErrorMessage } from 'utils/helpers'
import { PACKAGES_TYPES } from './constants'
import {
  calculatePriceReq,
  createSourceKeyReq,
  getPaymentProviderList,
  getPaymentStatusReq,
  getPurchasedPackagesList,
} from './providers'
import { packagesDomains, packagesSelectors } from './selectors'
import { PackagesActions } from './slice'
import { PaymentProviderItem, PurchasedPackages } from './types'
import { profileActions } from '../Profile/slice'

export function* getPurchasedPackages() {
  yield put(PackagesActions.setIsLoadingPackages(true))
  try {
    yield put(profileActions.getOrganizations())
    const response: AxiosResponse = yield getPurchasedPackagesList()
    let sourceKeys = response?.data?.data?.clientSourceKeys ?? []
    console.log('🚀 ~ function*getPurchasedPackages ~ response:', response)
    console.log('🚀 ~ function*getPurchasedPackages ~ sourceKeys:', sourceKeys)
    if (sourceKeys === null) {
      sourceKeys = []
    }
    yield put(PackagesActions.setPurchasedApplications(sourceKeys))
  } catch (error) {
    console.log({ error })
  } finally {
    yield put(PackagesActions.setIsLoadingPackages(false))
  }
}

export function* createNewSourceKeyFromForm() {
  yield put(PackagesActions.setIsLoadingGettingPaymentLink(true))

  const {
    clientDomainUuid,
    quantity,
    sourceKeyUUID,
    renewal,
    plan,
    userDefinedID,
  } = yield select(packagesDomains.newSourceKey)
  yield put(
    PackagesActions.createNewSourceKey({
      clientDomainUuid,
      quantity,
      sourceKeyUUID,
      renewal,
      plan,
      userDefinedID,
    }),
  )
}

export function* createNewSourceKey(
  action: ReturnType<typeof PackagesActions.createNewSourceKey>,
) {
  const {
    clientDomainUuid,
    quantity,
    sourceKeyUUID,
    renewal,
    plan,
    userDefinedID,
  } = action.payload

  console.log({
    clientDomainUuid,
    quantity,
    sourceKeyUUID,
    renewal,
    plan,
  })

  const fields: string[] = yield select(
    packagesSelectors.newSourceKeySelectedFields,
  )
  const optionalFields: string[] = yield select(
    packagesSelectors.newSourceKeyOptionalFields,
  )
  // FIXME: this is a hack to get the provider code
  const providerCode: PaymentProviderItem[] = yield select(
    packagesDomains.paymentProviders,
  )

  const isBundle = plan === PACKAGES_TYPES.BUNDLE
  const commonData = {
    clientDomainUuid,
    providerCode: providerCode[0]?.code || 'STRIPE',
  }
  // the request data is different for renewal and new purchase
  let createSourceKeyReqData

  // if it's a renewal, we need to check if it's a bundle or not
  if (renewal) {
    // if it's a bundle, we need to send the quantity
    if (isBundle) {
      createSourceKeyReqData = {
        ...commonData,
        quantity,
        sourceKeyUUID,
      }
      // if it's "Pay as you go"(CHARGE), we need to send the chargeAmount
    } else {
      createSourceKeyReqData = {
        ...commonData,
        chargeAmount: quantity,
        sourceKeyUUID,
      }
    }
    // if it's a new purchase, again we need to check if it's a bundle or not
  } else {
    // if it's a bundle, we need to send the quantity, fields and optionalFields
    if (isBundle) {
      createSourceKeyReqData = {
        ...commonData,
        quantity,
        fields,
        optionalFields,
        plan,
      }
      // if it's "Pay as you go"(CHARGE), we need to send the chargeAmount
    } else {
      createSourceKeyReqData = {
        ...commonData,
        chargeAmount: quantity,
        plan,
      }
    }
  }

  try {
    const response: AxiosResponse = yield createSourceKeyReq({
      ...createSourceKeyReqData,
      userDefinedID,
    })

    yield put(
      PackagesActions.setNewPackageValues({
        orderId: response?.data?.data?.orderID,
        paymentLink: response?.data?.data?.paymentLink,
      }),
    )
  } catch (error) {
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  } finally {
    yield put(PackagesActions.setIsLoadingGettingPaymentLink(false))
  }
}

export function* buyVideo(action: ReturnType<typeof PackagesActions.buyVideo>) {
  yield put(PackagesActions.setIsBuyingVideo(true))
  try {
    const response: AxiosResponse = yield createSourceKeyReq({
      ...action.payload,
    })
    const paymentLink = response?.data?.data?.paymentLink

    if (paymentLink) {
      window.location.href = paymentLink
    }
  } catch (error) {
    console.log({ error })
  } finally {
    yield put(PackagesActions.setIsBuyingVideo(false))
  }
}

export function* getPaymentProviders() {
  const response: AxiosResponse = yield getPaymentProviderList()
  yield put(
    PackagesActions.setPaymentProviders(
      response?.data?.data?.paymentProviders ?? [],
    ),
  )
}

export function* getPaymentStatus(
  action: ReturnType<typeof PackagesActions.getPaymentStatus>,
) {
  try {
    const response: AxiosResponse = yield getPaymentStatusReq(action)
    customToast.success(response?.data?.message || 'Process succeded')
  } catch (error: any) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  }
}

export function* calculatePrice(
  action: ReturnType<typeof PackagesActions.calculatePrice>,
) {
  yield put(PackagesActions.setIsFetchingPackagePrice(true))
  try {
    const response: AxiosResponse = yield calculatePriceReq(action)
    yield put(PackagesActions.setSelectedPackagePrice(response?.data?.data))
  } catch (error: any) {
    console.log({ error })
    const errorMessage = txErrorMessage(error)
    customToast.error(errorMessage)
  } finally {
    yield put(PackagesActions.setIsFetchingPackagePrice(false))
  }
}

export function* startPackageRenewal(
  action: ReturnType<typeof PackagesActions.startPackageRenewal>,
) {
  const purchasedPackage: PurchasedPackages = yield select(
    packagesSelectors.getPurchasePackageByUUID(action.payload),
  )
  const newPackageValueData = {
    sourceKeyUUID: purchasedPackage.uuid,
    quantity: purchasedPackage.quantity | purchasedPackage.chargeAmount,
    clientDomainUuid: purchasedPackage.clientDomainUUID,
    plan: purchasedPackage.plan,
    renewalSelectedFields: {
      renewalFields: purchasedPackage.fields,
      renewalOptionalFields: purchasedPackage.optionalFields,
    },
  }
  yield put(PackagesActions.setNewPackageValues(newPackageValueData))
}

export function* packagesSaga() {
  yield takeLatest(
    PackagesActions.getPurchasedPackages.type,
    getPurchasedPackages,
  )
  yield takeLatest(
    PackagesActions.createNewSourceKeyFromForm.type,
    createNewSourceKeyFromForm,
  )
  yield takeLatest(PackagesActions.createNewSourceKey.type, createNewSourceKey)
  yield takeLatest(
    PackagesActions.getPaymentProviders.type,
    getPaymentProviders,
  )
  yield takeLatest(PackagesActions.getPaymentStatus.type, getPaymentStatus)
  yield takeLatest(PackagesActions.calculatePrice.type, calculatePrice)
  yield takeLatest(
    PackagesActions.startPackageRenewal.type,
    startPackageRenewal,
  )
  yield takeLatest(PackagesActions.buyVideo.type, buyVideo)
}
