/* eslint-disable react-hooks/rules-of-hooks */
import { PayloadAction } from '@reduxjs/toolkit'
import { ContainerState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { productsSaga } from './saga'
import { VideoData } from './mock'

// The initial state of the Products container
export const initialState: ContainerState = {
  selectedVideo: null,
  isCheckingAge: false,
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSelectedVideo(state, action: PayloadAction<VideoData>) {
      state.selectedVideo = action.payload
    },
  },
})

export const {
  actions: ProductsActions,
  reducer: ProductsReducer,
  name: sliceKey,
} = productsSlice

export const useProductsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: ProductsReducer })
  useInjectSaga({ key: sliceKey, saga: productsSaga })
  return { ProductsActions }
}
