import { Badge } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { profileSelectors } from 'app/containers/Profile/selectors'
import { CheckCircle, Close } from '@mui/icons-material'
export const AgeVerifyBadge: FC = () => {
  const isOver18 = useSelector(profileSelectors.isOver18)
  const age = useSelector(profileSelectors.age)
  const country = useSelector(profileSelectors.country)
  if (age && age < 18) {
    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-center gap-2">
          <Badge>
            <Close color="error" />
          </Badge>
          <span className="text-sm font-bold">
            You are not old enough to view this content
          </span>
        </div>
        <span className="text-sm font-bold">Nationality : {country}</span>
      </div>
    )
  }
  if (!isOver18) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-center gap-2">
        <Badge>
          <CheckCircle color="success" />
        </Badge>
        <span className="text-sm font-bold">Age Verified {age} years old</span>
      </div>
      <span className="text-sm font-bold">country: {country}</span>
    </div>
  )
}
