import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'
import { profileDomains } from '../Profile/selectors'
export const productsDomains = {
  root: (state: RootState) => state.products || initialState,
  selectedVideo: (state: RootState) => state.products.selectedVideo,
}

export const productsSelectors = {
  root: createSelector(productsDomains.root, root => root),
  selectedVideo: createSelector(
    productsDomains.selectedVideo,
    selectedVideo => selectedVideo,
  ),
  isCheckingAge: createSelector(
    profileDomains.kycLoading,
    kycLoading => kycLoading,
  ),
}
