import styled from 'styled-components'
import { ROW_ALIGN_START_JUSTIFY_START } from 'styles/globalStyles'

export const OutletWrapper = styled('div')`
  width: calc(100% - 48px);
  margin-left: calc(264px + 16px);
  max-width: calc(100vw - 32px);
  overflow-y: auto;
  padding: 48px;
`

export const NavAndAppWrapper = styled('div')``

export const OutletSideBarWrapper = styled('div')`
  ${ROW_ALIGN_START_JUSTIFY_START}
  width:100%;
  max-width: 1920px;
  margin: auto;
  padding-top: 50px;
`
