import { useEffect } from 'react'
import LoginModal from './modals/login'
import { useDispatch, useSelector } from 'react-redux'
import { LoginSelectors } from '../Login/selectors'
import { PackagesActions } from '../Package/slice'
import { ApplicationsActions } from '../Applications/slice'
import { profileSelectors } from '../Profile/selectors'
const Global = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(LoginSelectors.isLoggedIn)
  const isUserUnregistered = useSelector(profileSelectors.isUserUnregistered)
  console.log('🚀 ~ Global ~ isUserUnregistered:', isUserUnregistered)
  useEffect(() => {
    if (isLoggedIn && !isUserUnregistered) {
      dispatch(PackagesActions.getPurchasedPackages())
      dispatch(ApplicationsActions.getApplications())
    }
  }, [isLoggedIn, dispatch, isUserUnregistered])
  return (
    <>
      <LoginModal />
    </>
  )
}

export default Global
